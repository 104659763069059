import React from "react"
import styled from "styled-components"
import urlBuilder from "@sanity/image-url"

const urlFor = source =>
  urlBuilder({ projectId: "4frnh5cs", dataset: "production" }).image(source)

export const serializers = {
  types: {
    figure: props => {
      return (
        <Wrapper>
          <ImgStyled src={urlFor(props.node.image)} alt={props.node.title} />
        </Wrapper>
      )
    },
  },
}

const ImgStyled = styled.img`
  width: 500px;
  margin: 3rem 0;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
